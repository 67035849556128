<template>
  <div class="number-card" :class="{ 'sub-support': obj.subtitle }">
    <div class="d-flex" :class="{ 'wrap-content': !!obj.num && obj.num.length > 5 }">
      <div class="num">
        <p :class="{ 'w-subtitle': obj.subtitle }">{{ obj.num | leftPad }}</p>
      </div>
      <div class="content">
        <div v-if="obj.subtitle" class="w-subtitle">
          <p class="subtitle" v-if="obj.subtitle">{{ subTitle }}</p>
          <p v-if="obj.title">{{ title }}</p>
        </div>
        <div v-else class="wo-subtitle">
          <p v-if="obj.title">{{ title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

export default {
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'number-card': {
            tcCampMembersInRecentEvents: 'Camp members participating in scripture events past 12 months',
            tcChurchExtremelySupportive: 'Churches are marked extremely supportive',
            tcChurchNoContact12Months: 'Churches with no contact the last 12 mos',
            tcConversationsPast12mos: 'Conversations in the past 12 mos',
            tcConversationAttendeesPast12mos: 'Attendees in the past 12 mos',
            tcCurrentFiscalYear: 'Current Fiscal Year',
            tcDaysSinceLastCandidate: 'Days since last candidate was added',
            tcFacilitiesServicedPast12Months: 'Facilities Serviced in the past 12 months',
            tcGideonCandidatesYTD: 'Gideon candidates identified year to date',
            tcGideonCardFacilitiesInCamp: 'GideonCard facilities Listed in your camp',
            tcGideonCardReceiptsFiscalYear: 'GideonCard Receipts Fiscal Year',
            tcNewAuxiliaryMembersYTD: 'New auxiliary members fiscal year to date',
            tcNewGideonMembersYTD: 'New Gideon members fiscal year to date',
            tcPast12Mos: 'Past 12 Mos',
            tcPersonalWorkersTestamentsOrderedThisYear: 'Camp personal workers testaments ordered fiscal year',
            tcPresentations: 'Presentations',
            tcScriptureEventsPast12Months: 'Scripture events by your camp in the past 12 months',
            tcScripturesOrderedThisYear: 'Camp scriptures ordered this fiscal year',
            tcVideoPresentationsPast36Months: 'Video presentations in the past 36 months',
          },
        }
      },
    },
    obj: Object,
  },
  components: {},
  methods: {},
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
    subTitle() {
      const propsSubTitle = this.obj.subtitle
      if (propsSubTitle === 'Current Fiscal Year') {
        return this.i18n['number-card'].tcCurrentFiscalYear
      } else if (propsSubTitle === 'Past 12 Mos') {
        return this.i18n['number-card'].tcPast12Mos
      }
      return propsSubTitle
    },
    title () {
      const propsTitle = this.obj.title.trim()

      switch (propsTitle) {
        case 'Camp members participating in scripture events past 12 months':
          return this.i18n['number-card'].tcCampMembersInRecentEvents
        case 'Churches with no contact the last 12 mos':
          return this.i18n['number-card'].tcChurchNoContact12Months
        case 'Churches are marked extremely supportive':
          return this.i18n['number-card'].tcChurchExtremelySupportive
        case 'Conversations in the past 12 month':
          return this.i18n['number-card'].tcConversationsPast12mos
        case 'Attendees in the past 12 month':
          return this.i18n['number-card'].tcConversationAttendeesPast12mos
        case 'Days since last candidate was added':
          return this.i18n['number-card'].tcDaysSinceLastCandidate
        case 'Facilities Serviced in the past 12 months':
          return this.i18n['number-card'].tcFacilitiesServicedPast12Months
        case 'Gideon candidates identified year to date':
          return this.i18n['number-card'].tcGideonCandidatesYTD
        case 'GideonCard facilities Listed in your camp':
          return this.i18n['number-card'].tcGideonCardFacilitiesInCamp
        case 'GideonCard Receipts Fiscal Year':
          return this.i18n['number-card'].tcGideonCardReceiptsFiscalYear
        case 'New Gideon members fiscal year to date':
          return this.i18n['number-card'].tcNewGideonMembersYTD
        case 'New auxiliary members fiscal year to date':
          return this.i18n['number-card'].tcNewAuxiliaryMembersYTD
        case 'Presentations':
          return this.i18n['number-card'].tcPresentations
        case 'Camp personal workers testaments ordered fiscal year':
          return this.i18n['number-card'].tcPersonalWorkersTestamentsOrderedThisYear
        case 'Scripture events by your camp in the past 12 months':
          return this.i18n['number-card'].tcScriptureEventsPast12Months
        case 'Camp scriptures ordered this fiscal year':
          return this.i18n['number-card'].tcScripturesOrderedThisYear
        case 'Video presentations in the past 36 months':
          return this.i18n['number-card'].tcVideoPresentationsPast36Months
        default:
          return propsTitle
      }
    }
  },
  filters: {
    leftPad: (value) => {
      if (!value) return '00'
      if (value.toString().length === 1) {
        return '0' + value
      } else {
        return value
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
.number-card {
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 24px 25px;
  background-color: #fff;
  @include breakpoint(sm) {
    .d-flex {
      flex-wrap: wrap;
    }
  }
  .num {
    padding-right: 17px;
    p {
      margin: 0;
      color: #3a515f;
      font-family: $DIN;
      font-size: 90px;
      letter-spacing: 3px;
      line-height: 90px;
      @include breakpoint(sm) {
        line-height: 100px;
      }
      &.w-subtitle {
        font-size: 76px;
        letter-spacing: 2.53px;
        line-height: 76px;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .w-subtitle {
      .subtitle {
        margin-bottom: 2px;
        font-size: 17px;
        line-height: 29px;
        color: #8f8f8f;
      }
      p {
        width: 100%;
        margin: 0;
        color: #000;
        font-size: 36px;
        font-family: $DIN;
        letter-spacing: 1px;
        line-height: 33px;
        text-transform: uppercase;
      }
    }
    .wo-subtitle {
      p {
        width: 100%;
        margin: 0;
        color: #000;
        font-size: 32px;
        font-family: $DIN;
        letter-spacing: 1px;
        line-height: 40px;
        text-transform: uppercase;
      }
    }
  }
}
.wrap-content {
  flex-wrap: wrap;
}
</style>
