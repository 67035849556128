<template>
  <div class='d-flex center align-items-center flex-column'>
    <svg id="Icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="82" height="80" viewBox="0 0 82 80">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="0.966" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#446276"/>
          <stop offset="1" stop-color="#003946"/>
        </linearGradient>
      </defs>
      <path id="Fill_1" data-name="Fill 1" d="M20.627,0H1.535C.689,0,0,.5,0,1.111S.685,2.222,1.535,2.222H20.627c.846,0,1.535-.5,1.535-1.111S21.477,0,20.627,0Z" transform="translate(55.405 28.889)" fill="url(#linear-gradient)"/>
      <path id="Fill_2" data-name="Fill 2" d="M20.627,0H1.535C.689,0,0,.5,0,1.111S.685,2.222,1.535,2.222H20.627c.846,0,1.535-.5,1.535-1.111S21.477,0,20.627,0Z" transform="translate(55.405 24.444)" fill="url(#linear-gradient)"/>
      <path id="Fill_3" data-name="Fill 3" d="M20.627,0H1.535C.689,0,0,.5,0,1.111S.685,2.222,1.535,2.222H20.627c.846,0,1.535-.5,1.535-1.111S21.477,0,20.627,0Z" transform="translate(55.405 20)" fill="url(#linear-gradient)"/>
      <path id="Fill_4" data-name="Fill 4" d="M0,1.111c0,.613.685,1.111,1.535,1.111H20.627c.846,0,1.535-.5,1.535-1.111S21.477,0,20.627,0L1.535,0C.689,0,0,.5,0,1.111Z" transform="translate(55.405 15.556)" fill="url(#linear-gradient)"/>
      <path id="Fill_5" data-name="Fill 5" d="M40.648,37.778H11.682a1.476,1.476,0,0,1-1.46-1.488V32.237l-7.964,5.3a1.443,1.443,0,0,1-1.836-.2,1.507,1.507,0,0,1-.175-1.872l9.975-15.189V1.488A1.476,1.476,0,0,1,11.683,0H31.722l.015,0,.013,0a1.568,1.568,0,0,1,.258.029.083.083,0,0,0,.023,0l.011,0a1.716,1.716,0,0,1,.21.067l.037.017.039.019c.058.029.11.058.16.089l.028.018.038.024a1.458,1.458,0,0,1,.2.163l0,0,8.918,9.1,0,0a1.413,1.413,0,0,1,.16.2l.024.039.017.028a1.422,1.422,0,0,1,.087.163.484.484,0,0,1,.027.058l.008.02a1.626,1.626,0,0,1,.067.217c0,.009,0,.017.008.025l0,.007a2.2,2.2,0,0,1,.024.266.076.076,0,0,0,0,.011.1.1,0,0,1,0,.017l0,25.7A1.476,1.476,0,0,1,40.648,37.778ZM11.681,28.007a1.446,1.446,0,0,1,.7.179,1.489,1.489,0,0,1,.763,1.307V34.8H39.188V12.075H31.72a1.476,1.476,0,0,1-1.46-1.489V2.975H13.14V20.72a1.505,1.505,0,0,1-.244.827l-6.25,9.518,4.238-2.82A1.444,1.444,0,0,1,11.681,28.007Z" transform="translate(39.892)" fill="url(#linear-gradient)"/>
      <path id="Fill_6" data-name="Fill 6" d="M26.595,13.332A13.3,13.3,0,1,1,13.3,0a13.316,13.316,0,0,1,13.3,13.332" transform="translate(6.649 28.889)" fill="url(#linear-gradient)"/>
      <path id="Fill_7" data-name="Fill 7" d="M30.13,0H11.975A11.821,11.821,0,0,0,0,11.665V20H42.108V11.665A11.828,11.828,0,0,0,30.13,0Z" transform="translate(0 60)" fill="url(#linear-gradient)"/>
    </svg>
    <h5 class='d-block text-uppercase mt-2 mb-3'>{{ displayText }}</h5>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ['lang', 'displayText'],
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

div.d-flex>h5 {
  font-family: $DIN, sans-serif;
  color: #003946;
  font-size: 26px;
}

</style>
