<template>
  <div :class="obj.class">
    <div class="card col border-bot info-card">
      <img
        :src="require(`@/assets/${obj.img.src}`)"
        :alt="obj.img.alt"
        :height="obj.img.height"
        :width="obj.img.width"
      />
      <div class="card-body">
        <h5 class="card-title" v-html="obj.title"></h5>
        <p class="card-text">{{ charLimit(obj.content, 175) }}</p>
        <b-button :to="obj.link" v-if="obj.link" variant="lighten">{{ obj.label }}</b-button>
        <b-button @click.prevent="handleButtonClick" v-if="obj.emitlink" variant="lighten">{{ obj.label }}</b-button>

        <b-button v-if="obj.extlink && obj.extLinkSideEffect" variant="lighten" @click.prevent="extLinkWithSideEffectHandler">
          <b-link  style="text-decoration: none; color: #fff;">{{
            obj.label
          }}</b-link>
        </b-button>
        <b-button v-else-if="obj.extlink" variant="lighten">
          <b-link target="_blank" v-if="obj.extlink" :href="obj.extlink" style="text-decoration: none; color: #fff;">{{
            obj.label
          }}</b-link>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

export default {
  props: {
    obj: Object
  },
  methods: {
    charLimit(text, limit) {
      if (!!text) {
        return text.length > limit ? text.substring(0, limit) + '...' : text
      }
    },
    async handleButtonClick() {
      this.$emit('set_program', this.obj)
    },
    extLinkWithSideEffectHandler() {
      this.$emit('extLinkSideEffect', this.obj)
    }
  },
  computed: {
    ...mapGetters({
      userLogin: 'user/userLogin'
    }),
    image(url) {
      return require(url)
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.info-card {
  text-align: center;
  margin-bottom: 45px;
  height: calc(100% - 45px);

  @include breakpoint(sm) {
    margin-bottom: 25px;
  }

  img {
    margin: 0 auto;
  }

  h5.card-title {
    margin-bottom: 10px;
    color: #000;
    font-family: $DIN;
    font-size: 30px;
    letter-spacing: 0.9px;
    line-height: 44px;
    text-transform: uppercase;
    width: 100%;
  }

  .card-text {
    margin-bottom: 20px;
    width: 100%;
  }

  .card-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    a.btn.btn-lighten {
      align-self: flex-end;
    }
  }
}
</style>
