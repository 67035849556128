<template>
  <div class='d-flex center align-items-center flex-column'>
    <svg class='d-block' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="93.739" height="84.38" viewBox="0 0 93.739 84.38">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="0.966" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#446276"/>
          <stop offset="1" stop-color="#003946"/>
        </linearGradient>
      </defs>
      <path id="Fill_1" data-name="Fill 1" d="M92.175,84.38H1.562a1.566,1.566,0,0,1-1.379-2.3L21.743,41.446h.007l9.492-17.891V1.562A1.564,1.564,0,0,1,32.8,0H54.671a1.539,1.539,0,0,1,1.445.964,1.539,1.539,0,0,1-.339,1.7L50.631,7.813l5.145,5.144a1.564,1.564,0,0,1-1.106,2.668H39.015a1.5,1.5,0,0,1-1.166-.488,1.613,1.613,0,0,1-.389-1.074A1.493,1.493,0,0,1,39.014,12.5H50.9L47.316,8.918a1.569,1.569,0,0,1,0-2.211L50.9,3.125H34.358V23.488l9.856,17.934,0,.006a.363.363,0,0,1,.039.071l6.218,11.309L61.226,38.2h.015a1.554,1.554,0,0,1,.457-.41.047.047,0,0,0,.028-.017c.008-.007.015-.014.022-.014a1.535,1.535,0,0,1,.734-.185,1.631,1.631,0,0,1,.255.02h.027a1.592,1.592,0,0,1,.816.414c.038.039.072.076.107.115l.012.014a1.205,1.205,0,0,1,.08.109L93.469,81.946a1.561,1.561,0,0,1-1.293,2.434ZM23.833,44.193,4.169,81.255H89.228L72.465,56.59l-4.332,1.723a1.544,1.544,0,0,1-.574.11,1.576,1.576,0,0,1-.977-.34l-4.523-3.6-4.527,3.6a1.576,1.576,0,0,1-.977.34,1.544,1.544,0,0,1-.574-.11l-4.376-1.738L42.232,69.317a1.5,1.5,0,0,1-1.259.73,1.724,1.724,0,0,1-1.5-.964,1.463,1.463,0,0,1,.245-1.614L48.451,55.59,42.18,44.184,38.56,45.789a1.543,1.543,0,0,1-.634.136,1.572,1.572,0,0,1-1.038-.393l-3.894-3.453L29.1,45.528a1.561,1.561,0,0,1-1.672.257Zm38.221,6.73a1.574,1.574,0,0,1,.974.34l4.793,3.809,2.843-1.129-8.225-12.1L53.52,53.966,56.3,55.071l4.785-3.809A1.56,1.56,0,0,1,62.054,50.923Zm-29.061-12.5a1.563,1.563,0,0,1,1.039.4l4.18,3.7,2.453-1.086L35.9,32.766l-3.05-5.553L25.305,41.426l2.472,1.1,4.18-3.7A1.553,1.553,0,0,1,32.993,38.425ZM34.376,62.5H31.251V59.376h3.126V62.5Zm0-6.25H31.251V53.125h3.126V56.25Zm0-6.251H31.251V46.876h3.126V50Z" transform="translate(0 0)" fill="url(#linear-gradient)"/>
    </svg>
    <h5 class='d-block text-uppercase mt-2 mb-3'>{{ displayText }}</h5>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ['lang', 'displayText'],
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

div.d-flex>h5 {
  font-family: $DIN, sans-serif;
  color: #003946;
  font-size: 26px;
}

</style>
