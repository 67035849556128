var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"church-ministry template-1"},[_c('header',{staticClass:"page-header container"},[_c('h1',[_vm._v(_vm._s(_vm.translations.tcScriptureDistribution))])]),(!_vm.allowPage)?_c('security-banner',{attrs:{"i18n":_vm.translations.components}}):_vm._e(),(_vm.allowPage)?_c('div',[_c('camp-selector',{attrs:{"tooltip":_vm.translations.tcTooltip,"i18n":_vm.translations.components},on:{"camp_changed":function($event){return _vm.page_load($event)}}}),_c('div',{staticClass:"page-body container mx-med"},[_c('section',{staticClass:"section-1"},[_c('h4',[_vm._v(" "+_vm._s(_vm.translations.tcRecentScripturePlacementDates)+" "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"i-tooltip",attrs:{"title":_vm.translations.tcTooltip}},[_c('i-tooltip')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[(this.recent_placements.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.noRecentPlacementsMessage)+" ")]):_c('div',_vm._l((this.preppedRecentPlacements),function(recent_placement,index){return _c('DateCard',{key:index,attrs:{"i18n":_vm.translations.common,"obj":recent_placement.date}},[(_vm.translations.common)?_c('BasicDateCard',{attrs:{"i18n":_vm.translations.components,"obj":{
                    title: recent_placement.title,
                    description: recent_placement.description,
                    placement_key: recent_placement.placement_key,
                    schedule: recent_placement.schedule,
                    mtg_key: recent_placement.mtg_key,
                    loc_key: recent_placement.loc_key,
                    destination: recent_placement.destination
                  }}}):_vm._e()],1)}),1),_c('button',{staticClass:"btn btn-primary btn-w-med mb-3 mr-1",on:{"click":_vm.handleRecentPlacementDate}},[_vm._v(" "+_vm._s(_vm.translations.tcViewMore)+" ")]),(_vm.iCanSee(_vm.secured_controls.add_scripture_placement_facility_dates_button))?_c('button',{staticClass:"btn btn-primary btn-w-med mb-3",on:{"click":_vm.handleAddPlacement}},[_vm._v(" "+_vm._s(_vm.translations.tcAddScripturePlacement)+" ")]):_vm._e()]),_c('div',{staticClass:"col col-6"},_vm._l((_vm.topWidgetData),function(stat,index){return _c('NumberCard',{key:index,attrs:{"obj":stat,"i18n":_vm.translations.components}})}),1)])]),_c('section',{staticClass:"section-2"},[_c('h4',[_vm._v(_vm._s(_vm.translations.tcUpcomingScripturePlacementDates))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[(this.upcoming_placements.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.noUpcomingPlacementsMessage)+" ")]):_c('div',_vm._l((this.preppedUpcomingPlacements),function(upcoming_placement,index){return _c('DateCard',{key:index,attrs:{"i18n":_vm.translations.common,"obj":upcoming_placement.date}},[(_vm.translations.common)?_c('BasicDateCard',{attrs:{"obj":{
                    title: upcoming_placement.title,
                    description: upcoming_placement.description,
                    placement_key: upcoming_placement.placement_key,
                    schedule: upcoming_placement.schedule,
                    mtg_key: upcoming_placement.mtg_key,
                    loc_key: upcoming_placement.loc_key,
                    destination: upcoming_placement.destination
                  },"i18n":_vm.translations.components}}):_vm._e()],1)}),1),_c('router-link',{staticClass:"btn btn-primary btn-w-med mb-3",attrs:{"to":"/programs/sd/placement-dates"}},[_c('div',[_vm._v(_vm._s(_vm.translations.tcViewMore))])])],1),_c('div',{staticClass:"col col-6"},_vm._l((_vm.bottomWidgetData),function(stat,index){return _c('NumberCard',{key:index,attrs:{"obj":stat,"i18n":_vm.translations.components}})}),1)])]),_c('section',{staticClass:"section-3"},[_c('div',{staticClass:"row"},_vm._l((_vm.scriptureDistributionInfo),function(info,index){return _c('InfoCard',{key:index,attrs:{"obj":info},on:{"set_program":function($event){return _vm.handleSetProgram($event)}}})}),1)])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }