<template>
  <div v-if="obj" class="date" :class="{ 'presentation-date': futureDate }">
    <div>
      <span v-if="obj.month">{{ objMonth }}</span>
      <span v-if="obj.day">{{ obj.day }}</span>
      <span v-if="obj.year">{{ obj.year }}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import date_data from '@/json/date.json'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  mixins: [translationMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'date': {},
        }
      },
    },
    obj: Object
  },
  data() {
    return {
      objMonth: this.obj.month || '',
    }
  },
  created() {
    this.handleUpdatedProps()
  },
  computed: {
    futureDate() {
      let month_int = parseInt(
        date_data.months.find((mi) => mi.abbr.toLowerCase() === this.obj.month.toLowerCase()).value,
        10
      )
      return new Date(this.obj.year, month_int - 1, this.obj.day) > new Date()
    },
  },
  methods: {
    handleUpdatedProps() {
      if (!this.i18n) return false

      this.monthsJson = date_data.months
      const givenMonth = this.obj.month || ''
      const givenMonthGuid = this.getMonthGuid(givenMonth)
      this.objMonth = this.i18n[givenMonthGuid.guidAbbr] || givenMonth
    },
  },
  watch: {
    i18n: function() {
      return this.handleUpdatedProps()
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.date {
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  background-image: linear-gradient(135deg, #fbc301 0%, #ebdd92 100%);
  &.presentation-date {
    background-image: linear-gradient(135deg, #0098c7 0%, #92cfeb 100%);
  }
  span {
    display: block;
    color: #000;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    &:nth-of-type(1) {
      font-size: 17px;
      font-weight: 600;
      line-height: 23px;
    }
    &:nth-of-type(2) {
      margin-top: -5px;
      font-size: 36px;
      font-weight: 400;
      line-height: 36px;
    }
    &:nth-of-type(3) {
      margin-top: -2px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
</style>
