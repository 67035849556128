<template>
  <div class="content" @click="handleDateCardClick">
    <h3 :title="obj.title" class="semi-bold">{{ charLimit(obj.title, 30) }}</h3>
    <div class="text">
      <p :title="description" v-if="obj.description" class>{{ description }}</p>
      <p class="semi-bold" v-if="obj.schedule">{{ schedule }}</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import calendarService from '../../services/calendarService'
import date_data from '@/json/date.json'
import { empty_guid, meetings } from '@/json/data.json'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  mixins: [translationMixin, calendarMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'basic-date-card': {
            tcNoSpeakerScheduled: 'No Speaker Scheduled',
            tcNoTimeScheduled: 'No Time Scheduled',
          },
        }
      },
    },
    obj: Object,
  },
  computed: {
    ...mapGetters({
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin'
    }),
    description() {
      const propsDescription = this.obj.description
      if (propsDescription === 'No Speaker Scheduled') {
        return this.charLimit(this.i18n['basic-date-card'].tcNoSpeakerScheduled, 30)
      }
      if (this.i18n.common['placement-facility-types'] && this.obj.placement_key) {
        const placementFacilityType = this.i18n.common['placement-facility-types'].hasOwnProperty(
          this.obj.placement_key
        )
          ? this.i18n.common['placement-facility-types'][this.obj.placement_key]
          : propsDescription
        return this.charLimit(placementFacilityType, 30)
      }
      return propsDescription
    },
    schedule() {
      if (this.obj.contact_type) {
        return this.i18n.common['church-visit-types'][this.obj.contact_type]
      }
      if (this.obj.churchServiceTypeGuid && this.obj.weekdayGuid) {
        const weekday = this.i18n.common['days'][this.obj.weekdayGuid.toLowerCase()]
        const churchServiceType = this.i18n.common['church-service-type'][this.obj.churchServiceTypeGuid.toLowerCase()]
        const serviceTime = this.obj.schedule.split(', ')[1]

        return `${churchServiceType} - ${weekday}, ${serviceTime}`
      }

      const propsSchedule = this.obj.schedule
      if (propsSchedule === 'No Time Scheduled') {
        return this.i18n['basic-date-card'].tcNoTimeScheduled
      }

      if (!this.i18n.common.days) return propsSchedule

      this.daysJson = date_data.days
      const givenDay = propsSchedule || ''
      const givenWeekdayGuid = this.getWeekdayGuid(givenDay)

      if (Object.keys(givenWeekdayGuid).length === 0) {
        return propsSchedule
      } else {
        return this.i18n.common['days'][givenWeekdayGuid.guidFull] || propsSchedule
      }
    },
  },
  methods: {
    ...mapActions({
      clearSelectedEvent: 'eventCalendar/clearSelected',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
      setSelectedEncounter: 'prayerAndWitnessing/setSelectedEncounter',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    charLimit(text, limit) {
      if (text !== null) {
        return text.length > limit ? text.substring(0, limit) + '...' : text
      }
    },
    checkWhichSettingType(obj) {
      if (obj.visit_key && obj.entity_key) {
        return 'entity_key'
      }
      if (obj.visit_key) {
        return 'visit_key'
      }
      if (obj.mtg_key) {
        return 'mtg_key'
      }
      if (obj.cvs_mtg_key) {
        return 'cvs_mtg_key'
      }
      if (obj.wit_key) {
        return 'wit_key'
      }
      if (obj.meetingDetails && obj.meetingDetails.meetingtype === meetings.stateconvention.value) {
        return 'details_convention'
      }
      if (obj.meetingDetails) {
        return 'details_meeting'
      }
      return 'other'
    },
    async handleDateCardClick() {
      const {
        visit_key,
        destination,
        presentation_key,
        cvs_mtg_key,
        mtg_key,
        wit_key,
        meetingDetails,
        entity_key,
        wit_name,
        wit_content,
        wit_add_date,
        date,
      } = this.obj
      if (!!destination && (!!visit_key || !!presentation_key || !!cvs_mtg_key || !!mtg_key || !!wit_key || meetingDetails)) {
        let settingType = this.checkWhichSettingType(this.obj)
        switch (settingType) {
          case 'visit_key':
            await this.setSelectedVisitKey(visit_key)
            this.setRouterBackLink(this.$route.name)
            break;
          case 'entity_key':
            await this.setSelectedVisitKey(visit_key)
            this.setRouterBackLink(this.$route.name)
            await this.setSelectedChurchKey(entity_key)
            break;
          case 'mtg_key':
            await this.storePlacementKeyAndRedirect(this.obj)
            break;
          case 'cvs_mtg_key':
            await this.$emit('date_card_clicked', this.obj)
            break;
          case 'wit_key':
            const opport = {
              wit_key: wit_key,
              wit_name: wit_name,
              wit_content: wit_content,
              wit_add_date: wit_add_date,
              date: date,
            }
            await this.setSelectedEncounter(opport)
            break;
          case 'details_convention':
            await this.setSelectedConventionKey(meetingDetails.mtgkey)
            break;
          case 'details_meeting':
            if (!!meetingDetails.mtrkey && meetingDetails.mtrkey !== empty_guid) {
              let response = await calendarService.getMeetingRecurrence(meetingDetails.mtrkey)
              if (response.status === 200) {
                meetingDetails.reccurrencetype = response.data
              }
            }
            await this.setSelectedMeeting(meetingDetails)
            break;
          default:
            await this.setSelectedMeetingKey(presentation_key)
            break;
        }
        if (settingType !== 'mtg_key' && settingType !== 'cvs_mtg_key') {
          this.$emit('date_card_clicked')
          this.$router.push(destination)
        }
      }
    },
  },
  // watch: {
  //   i18n: function() {
  //     return this.schedule()
  //   },
  // },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

h3 {
  margin-bottom: 4px;
  color: #1b2831;
  font-family: $open-sans;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  text-transform: none;
}

p {
  margin-bottom: 2px;
  color: #747474;
  font-size: 14px;
  line-height: 19px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.semi-bold {
  font-weight: 600;
}
</style>
