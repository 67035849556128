<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48.8" height="48.801" viewBox="0 0 48.8 48.801">
    <defs></defs>
    <g id="Profile_Icon_Blue" transform="translate(-0.8 -0.699)">
      <g id="Group_1" data-name="Group 1">
        <path
          id="Path_1"
          data-name="Path 1"
          :class="className"
          d="M49.6,25.2c0,11.2-8.2,24.4-24.4,24.3C10.4,49.4.8,38.2.8,25.2.8,11.6,11.3.9,25.4.7,38.7.6,49.6,11.8,49.6,25.2ZM25.3,46.3A21,21,0,0,0,46.5,25.2a21.063,21.063,0,0,0-21-21.3,21.2,21.2,0,1,0-.2,42.4Z"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          :class="className"
          d="M38.9,38.2c-6.7,7.9-20.5,7.9-27.1.1a7.862,7.862,0,0,1,8.4-8.7c3.3.1,6.7,0,10,0C35.7,29.8,38.5,32.5,38.9,38.2Z"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          :class="className"
          d="M25.3,12.9a7.5,7.5,0,0,1,.2,15,7.683,7.683,0,0,1-7.7-7.6A7.468,7.468,0,0,1,25.3,12.9Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
/* eslint-disable */
export default {
  name: 'SquareButton',
  props: {
    className: { type: String, default: 'cls-1' },
  },
  computed: {
    style() {
      return 'background-color: ' + this.hovering ? this.color : 'red'
    },
  },
}
</script>

<style scoped>
.cls-1 {
  fill: #003946;
}
.cls-2 {
  fill: #fafafa;
}
</style>
